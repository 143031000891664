<template>
	<div class="wrapper">
		<div class="nav">
			<div class="item" :class="title ? 'font-color-red' : ''" @click="set_where(0)">{{ title ? title : '默认' }}</div>
			<div class="item" @click="set_where(1)">
				价格
				<img src="@assets/images/horn.png" v-if="price === 0" />
				<img src="@assets/images/up.png" v-if="price === 1" />
				<img src="@assets/images/down.png" v-if="price === 2" />
			</div>
			<div class="item" @click="set_where(2)">
				销量
				<img src="@assets/images/horn.png" v-if="stock === 0" />
				<img src="@assets/images/up.png" v-if="stock === 1" />
				<img src="@assets/images/down.png" v-if="stock === 2" />
			</div>
			<div class="item" :class="nows ? 'font-color-red' : ''" @click="set_where(3)">新品</div>
		</div>
		<Good-list v-loading="loading" :good-list="productList"></Good-list>
		<el-pagination
			:hide-on-single-page="true"
			@size-change="SizeChange"
			@current-change="CurrentChange"
			:current-page="where.page"
			:page-size="where.limit"
			:page-sizes="[30, 40, 50]"
			layout="total, sizes, prev, pager, next, jumper"
			:total="totalCount"
		></el-pagination>
	</div>
</template>

<script>
import GoodList from '@components/GoodList';
import { getProducts } from '@api/user';
export default {
	name: 'GoodsList',
	components: {
		GoodList
	},
	data() {
		const { s = '', cid = 0, sid = 0, title = '' } = this.$route.query;
		return {
			where: {
				page: 1,
				limit: 30,
				keyword: s,
				cid: cid, // 一级分类cid
				sid: sid, //二级分类sid
				news: 0,
				priceOrder: '',
				salesOrder: ''
			},
			title: (title && sid) || (title && cid) ? title : s,
			productList: [],
			price: 0,
			stock: 0,
			nows: false,
			totalCount: 0
		};
	},
	mounted: function() {
		let that = this;
		that.get_product_list();
		if (that.$route.query.loading) {
			that.loading = true;
		}
	},
	watch: {
		$route(to) {
			if (to.name !== 'GoodsList') return;
			const { s = '', cid = 0, sid = 0, title = '' } = to.query;
			let that = this;
			if (s !== that.where.keyword || cid !== that.where.cid || sid !== that.where.sid) {
				that.where.keyword = s;
				that.where.page = 1;
				that.where.cid = cid;
				that.where.sid = sid;
				(that.title = (title && sid) || (title && cid) ? title : s),
					// 重置
					(that.where.nows = 0);
				that.where.priceOrder = '';
				that.where.salesOrder = '';
				that.nows = false;
				(that.price = 0), (that.stock = 0), (that.loading = true);
				that.$set(that, 'productList', []);
				that.get_product_list();
			}
		}
	},
	methods: {
		//设置where条件
		setWhere: function() {
			let that = this;
			if (that.price === 0) {
				that.where.priceOrder = '';
			} else if (that.price === 1) {
				that.where.priceOrder = 'asc';
			} else if (that.price === 2) {
				that.where.priceOrder = 'desc';
			}
			if (that.stock === 0) {
				that.where.salesOrder = '';
			} else if (that.stock === 1) {
				that.where.salesOrder = 'asc';
			} else if (that.stock === 2) {
				that.where.salesOrder = 'desc';
			}
			that.where.news = that.nows ? '1' : '0';
		},
		//点击事件处理
		set_where: function(index) {
			let that = this;
			switch (index) {
				case 0:
					that.where.priceOrder = '';
					that.where.salesOrder = '';
					that.where.news = 0;
					break;
				case 1:
					if (that.price === 0) that.price = 1;
					else if (that.price === 1) that.price = 2;
					else if (that.price === 2) that.price = 0;
					that.stock = 0;
					break;
				case 2:
					if (that.stock === 0) that.stock = 1;
					else if (that.stock === 1) that.stock = 2;
					else if (that.stock === 2) that.stock = 0;
					that.price = 0;
					break;
				case 3:
					that.nows = !that.nows;
					break;
				default:
					break;
			}
			that.loading = true;
			that.$set(that, 'productList', []);
			that.where.page = 1;
			this.setWhere();
			that.get_product_list();
		},
		get_product_list: function() {
			let that = this;
			getProducts(that.where).then(res => {
				that.productList = res.data.goods;
				that.loading = false;
				that.totalCount = res.data.count;
			});
		},
		SizeChange(e) {
			let that = this;
			that.where.limit = e;
			that.get_product_list();
		},
		CurrentChange(e) {
			let that = this;
			that.where.page = e;
			that.get_product_list();
		}
	}
};
</script>

<style lang="less" scoped>
.nav {
	display: flex;
	height: 46px;
	line-height: 46px;
	align-items: center;
	margin-top: 20px;
	.item {
		margin: 0 20px;
		display: flex;
		align-items: center;
		cursor: pointer;
		img {
			width: 8px;
			margin-left: 10px;
		}
	}
	.font-color-red {
		color: #f60;
	}
}
</style>
